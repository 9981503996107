<template>
  <div class="team">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Team"
    />
    <div v-if="team">
      <v-card>
        <v-img
          :src="team.image"
          height="200px"
          class="px-3"
        >
          <v-row class="mb-4">
            <v-col>
              <div class="headline">
                {{ team.name }}
              </div>
              <div class="title">
                {{ team.number_of_members }} members
              </div>
            </v-col>
          </v-row>
          <v-row class="align-end pt-5">
            <v-col>
              <div class="title">
                {{ team.quests_completed }}
              </div>
              <div>
                Quests Completed
              </div>
            </v-col>
            <v-col align="right">
              <div class="title">
                {{ team.points_earned }}
              </div>
              <div>
                Points
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>

      <v-row>
        <v-col>
          <Share
            :title="team.name"
            class="mr-4"
          />
          <Flag
            :object-id="team.id"
            app="quest"
            model="team"
            class="mr-4"
          />
          <v-btn
            v-if="isLeader"
            class="mr-4"
            :to="{ name: 'TeamEdit', params: { id: team.id }}"
          >
            <v-icon>
              $edit
            </v-icon>
          </v-btn>
          <v-btn
            v-if="canInvite"
            class="mr-4"
            @click.stop="inviteDialog = true"
          >
            <v-icon>
              $register
            </v-icon>
          </v-btn>
          <v-btn
            v-if="membership"
            class="mr-4"
            @click.stop="leaveDialog = true"
          >
            Leave Team
          </v-btn>
          <v-btn
            v-if="canApply"
            class="mr-4"
            @click.stop="applyDialog = true"
          >
            Apply to Join
          </v-btn>
          <v-dialog
            v-model="inviteDialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :max-width="$vuetify.breakpoint.thresholds.sm"
          >
            <v-card>
              <v-toolbar>
                <v-btn
                  icon
                  dark
                  @click="inviteDialog = false"
                >
                  <v-icon>
                    $close
                  </v-icon>
                </v-btn>
                <v-toolbar-title>
                  {{ team.name }}: Invite Members
                </v-toolbar-title>
              </v-toolbar>
              <TeamInvite
                :team="team"
                :is-leader="isLeader"
                :visible="inviteDialog"
                @inviteSent="updateTeam"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="leaveDialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :max-width="$vuetify.breakpoint.thresholds.sm"
          >
            <v-card>
              <v-toolbar>
                <v-btn
                  icon
                  dark
                  @click="leaveDialog = false"
                >
                  <v-icon>
                    $close
                  </v-icon>
                </v-btn>
                <v-toolbar-title>
                  {{ team.name }}: Leave
                </v-toolbar-title>
              </v-toolbar>
              <TeamLeave
                :team="team"
                :member="membership"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="applyDialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :max-width="$vuetify.breakpoint.thresholds.sm"
          >
            <v-card>
              <v-toolbar>
                <v-btn
                  icon
                  dark
                  @click="applyDialog = false"
                >
                  <v-icon>
                    $close
                  </v-icon>
                </v-btn>
                <v-toolbar-title>
                  {{ team.name }}: Apply
                </v-toolbar-title>
              </v-toolbar>
              <TeamApply :team="team" />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row v-if="myInvite">
        <v-col>
          <TeamInviteResponse
            :invite="myInvite"
          />
        </v-col>
      </v-row>


      <v-row v-if="team.bio">
        <v-col>
          <div style="white-space: pre;">
            {{ team.bio }}
          </div>
        </v-col>
      </v-row>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab>
          Members
        </v-tab>
        <v-tab>
          Activity
        </v-tab>
        <v-tab v-if="canViewEvents">
          Events
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row>
            <v-col>
              <v-card flat>
                <v-card-title>
                  Members
                </v-card-title>
                <v-list
                  v-if="team.members.length"
                  max-height="338px"
                  class="overflow-y-auto"
                >
                  <v-list-item
                    v-for="member in team.members"
                    :key="member.id"
                    @click="showMember(member)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="member.avatar_thumbnail_url"
                        :src="member.avatar_thumbnail_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row align="center">
                        <v-col>
                          <v-list-item-title v-text="member.user" />
                        </v-col>
                        <v-col class="text-right">
                          <div
                            v-if="member.is_leader"
                            class="points-label"
                          >
                            Leader
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-dialog
                v-if="memberDetails"
                v-model="memberDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="memberDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <router-link :to="{ name: 'SocialProfile', params: { username: memberDetails.user }}">
                        {{ memberDetails.user }}
                      </router-link>
                    </v-toolbar-title>
                  </v-toolbar>
                  <TeamMember
                    :member="memberDetails"
                    :is-leader="isLeader"
                    :visible="memberDialog"
                    @memberChanged="updateTeam"
                  />
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row v-if="isLeader && applications.length">
            <v-col>
              <v-card>
                <v-card-title flat>
                  Applications
                </v-card-title>
                <v-list
                  max-height="338px"
                  class="overflow-y-auto"
                >
                  <v-list-item
                    v-for="application in applications"
                    :key="application.id"
                    @click="showApplication(application)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="application.avatar_thumbnail_url"
                        :src="application.avatar_thumbnail_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row align="center">
                        <v-col>
                          <v-list-item-title v-text="application.user" />
                        </v-col>
                        <v-col class="text-right">
                          <div class="points-label">
                            {{ application.created|formatDate }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-dialog
                v-if="applicationDetails"
                v-model="applicationDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="applicationDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      Application
                    </v-toolbar-title>
                  </v-toolbar>
                  <TeamApplication
                    :application="applicationDetails"
                    :is-leader="isLeader"
                    :visible="applicationDialog"
                    @applicationChanged="updateTeam"
                  />
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row v-if="isLeader && invitations.length">
            <v-col>
              <v-card flat>
                <v-card-title>
                  Invitations
                </v-card-title>
                <v-list
                  max-height="338px"
                  class="overflow-y-auto"
                >
                  <v-list-item
                    v-for="invitation in invitations"
                    :key="invitation.id"
                    @click="showInvitation(invitation)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="invitation.invitee.avatar_thumbnail_url"
                        :src="invitation.invitee.avatar_thumbnail_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row align="center">
                        <v-col>
                          <v-list-item-title v-text="invitation.invitee.user" />
                        </v-col>
                        <v-col class="text-right">
                          <div class="points-label">
                            {{ invitation.created|formatDate }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-dialog
                v-if="invitationDetails"
                v-model="invitationDialog"
                :fullscreen="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.thresholds.sm"
              >
                <v-card>
                  <v-toolbar>
                    <v-btn
                      icon
                      dark
                      @click="invitationDialog = false"
                    >
                      <v-icon>
                        $close
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      Invitation
                    </v-toolbar-title>
                  </v-toolbar>
                  <TeamInvitation
                    :invitation="invitationDetails"
                    :is-leader="isLeader"
                    :visible="invitationDialog"
                    @invitationChanged="updateTeam"
                  />
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <EvidenceLocker :team="team.id" />
        </v-tab-item>
        <v-tab-item v-if="canViewEvents">
          <EventList
            :can-create="canCreateEvents"
            :team="team"
            :url="`/quest/event/?team=${team.id}`"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue'
import EvidenceLocker from '@/components/EvidenceLocker.vue'
import Flag from '@/components/Flag.vue'
import Loading from '@/components/Loading.vue'
import Share from '@/components/Share.vue'
import TeamApplication from '@/components/TeamApplication.vue'
import TeamApply from '@/components/TeamApply.vue'
import TeamInvitation from '@/components/TeamInvitation.vue'
import TeamInvite from '@/components/TeamInvite.vue'
import TeamInviteResponse from '@/components/TeamInviteResponse.vue'
import TeamLeave from '@/components/TeamLeave.vue'
import TeamMember from '@/components/TeamMember.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    EventList,
    EvidenceLocker,
    Flag,
    Loading,
    Share,
    TeamApply,
    TeamLeave,
    TeamInvite,
    TeamInviteResponse,
    TeamApplication,
    TeamInvitation,
    TeamMember,
  },
  data: function() {
    return {
      alerts: [],
      applicationDetails: null,
      applicationDialog: false,
      applications: [],
      invitationDetails: null,
      invitationDialog: false,
      invitations: [],
      applyDialog: false,
      leaveDialog: false,
      inviteDialog: false,
      loading: false,
      loadingError: false,
      memberDetails: null,
      memberDialog: false,
      tab: null,
      team: null,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    ...mapGetters({
      getMyInviteForTeam: 'teaminvitations/getMyInviteForTeam',
    }),
    myInvite() {
      return this.getMyInviteForTeam(this.team.id)
    },
    membership() {
      return this.team.members.find(obj => obj.user == this.profile.user)
    },
    canViewEvents() {
      return this.profile.allow_events && (this.team.allow_public_events || this.membership)
    },
    canCreateEvents() {
      if (this.profile.allow_events && this.membership) {
        return true
      }
      return false
    },
    isLeader() {
      return this.membership && this.membership.is_leader
    },
    canApply() {
      return this.team.accept_applications && !this.membership
    },
    canInvite() {
      return this.isLeader
    },
  },
  created: function () {
    this.updateTeam(true)
  },
  methods: {
    updateTeam(loading) {
      this.getDetails(loading).finally(() => {
        this.getApplications()
        this.getInvitations()
        this.$store.dispatch('teaminvitations/getAll')
      })
    },
    getApplications() {
      if (this.membership) {
        return this.$store.dispatch('teamapplications/loadForTeam', this.team.id)
          .finally(() => {
            this.applications = this.$store.getters['teamapplications/getForTeamByID'](this.team.id)
          })
      }
    },
    getInvitations() {
      if (this.membership) {
        return this.$store.dispatch('teaminvitations/loadForTeam', this.team.id)
          .finally(() => {
            this.invitations = this.$store.getters['teaminvitations/getForTeamByID'](this.team.id)
          })
      }
    },
    getDetails(loading) {
      if (loading) {
        this.loading = true
      }
      return this.$store.dispatch('teams/getDetails', '/quest/team/' + this.$route.params.id + '/')
        .finally(() => {
          this.team = this.$store.getters['teams/getDetailsByID'](this.$route.params.id) || null
          this.loading = false
          if(!this.team) {
            this.loadingError = true
            if (!this.$online) {
              this.alerts.push({
                type: 'error',
                message: 'An internet connection is required to download the details of this team.',
              })
            } else {
              this.alerts.push({
                type: 'error',
                message: 'Failed to load team.',
              })
            }
          }
        })
    },
    showApplication (application) {
      this.applicationDetails = application
      this.applicationDialog = true
    },
    showInvitation (invitation) {
      this.invitationDetails = invitation
      this.invitationDialog = true
    },
    showMember (member) {
      this.memberDetails = member
      this.memberDialog = true
    },
  },
}
</script>
