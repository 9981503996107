<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          You have left the team
        </p>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <div class="description">
          <p>
            Are you sure you want to leave {{ team.name }}?
          </p>
          <p v-if="team.number_of_members <= 1">
            You are the only member. Leaving will cause the team to be deleted.
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :disabled="submitting"
          @click="submit"
        >
          Leave
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      success: null,
      errorMessages: null,
      submitting: false,
    }
  },
  methods: {
    submit () {
      return allianceAPI.delete(`/quest/team/member/${this.member.id}/dismiss/`)
        .then(() => {
          this.success = true
          this.$router.push({ name: 'Teams' })
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
