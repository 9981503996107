<template>
  <div>
    <v-alert
      v-for="error in errorMessages"
      :key="error"
      type="error"
    >
      {{ error }}
    </v-alert>
    <v-alert
      v-if="success"
      type="success"
    >
      You have {{ status }} the invitation.
    </v-alert>
    <v-alert
      v-else
      text
      type="info"
    >
      <v-row no-gutters>
        <v-col>
          You have been invited to this team by {{ invite.inviter.user }}.
        </v-col>
        <v-col
          :align="$vuetify.breakpoint.xsOnly ? 'left' : 'right'"
          cols="12"
          sm="6"
        >
          <v-btn
            color="info"
            class="mr-4"
            :disabled="submitting"
            @click="accept"
          >
            Accept
          </v-btn>
          <v-btn
            color="error"
            :disabled="submitting"
            @click="reject"
          >
            Reject
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    invite: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      errorMessages: null,
      status: '',
      submitting: false,
      success: null,
    }
  },
  methods: {
    accept() {
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch(`/quest/team/invitation/${this.invite.id}/accept/`)
        .then(() => {
          this.status = 'accepted'
          this.success = true
          this.$emit('inviteChanged')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
    reject() {
      this.submitting = true
      this.errorMessages = null
      return allianceAPI.patch(`/quest/team/invitation/${this.invite.id}/reject/`)
        .then(() => {
          this.status = 'rejected'
          this.success = true
          this.$emit('inviteChanged')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
