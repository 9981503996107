<template>
  <div>
    <v-card-text>
      <Loading
        :loading="loading"
        message="Loading Profile"
      />
      <div v-if="invitationProfile">
        <ProfileHeader :profile="invitationProfile" />
      </div>
      <div class="profile-meta mb-3">
        <v-row>
          <v-col>
            Invitation Date
          </v-col>
          <v-col class="text-right">
            {{ invitation.created|formatDate }}
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col>
            Invited By
          </v-col>
          <v-col class="text-right">
            {{ invitation.inviter.user }}
          </v-col>
        </v-row>
        <v-divider />
      </div>
    </v-card-text>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import ProfileHeader from '@/components/ProfileHeader.vue'

export default {
  components: {
    Loading,
    ProfileHeader,
  },
  props: {
    invitation: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      loading: false,
      applicationProfile: null,
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.invitationProfile = null
      this.getProfile()
    },
    getProfile() {
      this.loading = true
      return this.$store.dispatch('profiles/getDetails', `/alliance/profile/${this.invitation.invitee.user}/`)
        .finally(() => {
          this.invitationProfile = this.$store.getters['profiles/getDetailsByUsername'](this.invitation.invitee.user) || null
          this.loading = false
        })
    },
  },
}
</script>
