<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          Invite Sent
        </p>
        <p>
          You have invited {{ usernameList.length }} member<span v-if="usernameList.length > 1">s</span> to team {{ team.name }}.
        </p>
      </v-card-text>
    </v-card>
    <v-form
      v-else
      ref="form"
      @submit.prevent="submit"
    >
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <div class="description">
          Select the members you would like to invite to the team.
        </div>
        <ProfileMultiSelect 
          v-model="usernameList"
          :disabled="submitting"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          type="submit"
          :disabled="submitting"
        >
          Send Invite
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import ProfileMultiSelect from '@/components/ProfileMultiSelect.vue'

export default {
  components: {
    ProfileMultiSelect,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
    isLeader: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      success: null,
      usernameList: [],
      errorMessages: null,
      submitting: false,
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.success = null
      this.usernameList = []
      this.errorMessages = null
      this.submitting = false
    },
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.errorMessages = null
      this.submitting = true
      const data = new FormData()
      data.append('team', this.team.id)
      for (let i = 0; i < this.usernameList.length; i++) {
        data.append('invitees', this.usernameList[i]);
      }
      return allianceAPI.post(`/quest/team/invitation/`, data)
        .then(() => {
          this.success = true
          this.errorMessages = null
          this.$emit('inviteSent')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
