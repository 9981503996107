<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          Application {{ status }}
        </p>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card-text>
        <Loading
          :loading="loading"
          message="Loading Profile"
        />
        <div v-if="applicationProfile">
          <ProfileHeader :profile="applicationProfile" />
        </div>
        <div class="profile-meta mb-3">
          <v-row>
            <v-col>
              Application Date
            </v-col>
            <v-col class="text-right">
              {{ application.created|formatDate }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row v-if="application.message">
            <v-col>
              Message
            </v-col>
            <v-col class="text-right">
              {{ application.message }}
            </v-col>
          </v-row>
          <v-divider v-if="application.message" />
        </div>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions
        v-if="isLeader"
        class="justify-center"
      >
        <v-btn
          color="primary"
          :disabled="submitting"
          @click="approve"
        >
          Approve
        </v-btn>
        <v-btn
          :disabled="submitting"
          @click="reject"
        >
          Reject
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import ProfileHeader from '@/components/ProfileHeader.vue'
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    Loading,
    ProfileHeader,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    isLeader: {
      type: Boolean,
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      applicationProfile: null,
      errorMessages: null,
      loading: false,
      status: '',
      submitting: false,
      success: null,
    }
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.applicationProfile = null
      this.status = ''
      this.success = null
      this.getProfile()
    },
    getProfile() {
      this.loading = true
      return this.$store.dispatch('profiles/getDetails', `/alliance/profile/${this.application.user}/`)
        .finally(() => {
          this.applicationProfile = this.$store.getters['profiles/getDetailsByUsername'](this.application.user) || null
          this.loading = false
        })
    },
    approve() {
      if (this.isLeader) {
        this.submitting = true
        this.errorMessages = null
        return allianceAPI.patch(`/quest/team/application/${this.application.id}/approve/`)
          .then(() => {
            this.status = 'Approved'
            this.success = true
            this.$emit('applicationChanged')
          })
          .catch((error) => {
            this.errorMessages = parseResponseErrors(error)
            this.success = false
          })
          .finally(() => this.submitting = false)
      }
    },
    reject() {
      if (this.isLeader) {
        this.submitting = true
        this.errorMessages = null
        return allianceAPI.patch(`/quest/team/application/${this.application.id}/reject/`)
          .then(() => {
            this.status = 'Rejected'
            this.success = true
            this.$emit('applicationChanged')
          })
          .catch((error) => {
            this.errorMessages = parseResponseErrors(error)
            this.success = false
          })
          .finally(() => this.submitting = false)
      }
    },
  },
}
</script>
