<template>
  <div>
    <v-card
      v-if="success"
      flat
      align="center"
    >
      <v-card-text>
        <v-icon
          size="200"
          color="primary"
        >
          $check
        </v-icon>
        <p class="text-h4">
          Application Submitted
        </p>
        <p>
          A team leader will review your application and accept or reject it.
        </p>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-card-text>
          <v-alert
            v-for="error in errorMessages"
            :key="error"
            type="error"
          >
            {{ error }}
          </v-alert>
          <div class="description">
            <p>
              Let the team leaders know why you would be a valuable member.
            </p>
          </div>
          <v-textarea
            v-model="message"
            label="Message"
            auto-grow
            :disabled="submitting"
            :rules="messageRules"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            :disabled="submitting"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      success: null,
      errorMessages: null,
      submitting: false,
      message: '',
      messageRules: [
        v => v.length <= 4000 || 'Please restrain yourself to 4,000 characters',
      ],
    }
  },
  methods: {
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      const data = new FormData()
      data.append('team', this.team.id)
      data.append('message', this.message)
      return allianceAPI.post(`/quest/team/application/`, data)
        .then(() => {
          this.success = true
          this.errorMessages = null
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
