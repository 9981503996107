<template>
  <div>
    <v-card-text>
      <Loading
        :loading="loading"
        message="Loading Profile"
      />
      <div v-if="memberProfile">
        <ProfileHeader
          :profile="memberProfile"
          :show-meta="false"
        />
        <div class="profile-meta mb-3">
          <v-divider />
          <v-row>
            <v-col>
              Team Member Since
            </v-col>
            <v-col class="text-right">
              {{ member.created|formatDate }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col>
              Team Quests Completed
            </v-col>
            <v-col class="text-right">
              {{ member.quests_completed }}
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </div>
    </v-card-text>
    <v-card-actions
      v-if="isLeader"
      class="justify-center"
    >
      <v-btn
        v-if="!member.is_leader"
        :disabled="submitting"
        @click="promote"
      >
        Promote to Leader
      </v-btn>
      <v-btn
        v-if="!isSelf"
        :disabled="submitting"
        @click="dismiss"
      >
        Dismiss from Team
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import ProfileHeader from '@/components/ProfileHeader.vue'
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    Loading,
    ProfileHeader,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    isLeader: {
      type: Boolean,
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      errorMessages: null,
      memberProfile: null,
      submitting: false,
      success: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    isSelf() {
      return this.member.user == this.profile.user
    },
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.errorMessages = null
      this.memberProfile = null
      this.success = null
      this.getProfile()
    },
    getProfile() {
      this.loading = true
      return this.$store.dispatch('profiles/getDetails', `/alliance/profile/${this.member.user}/`)
        .finally(() => {
          this.memberProfile = this.$store.getters['profiles/getDetailsByUsername'](this.member.user) || null
          this.loading = false
        })
    },
    promote() {
      if (this.isLeader && !this.member.is_leader) {
        this.submitting = true
        this.errorMessages = null
        return allianceAPI.patch(`/quest/team/member/${this.member.id}/promote/`)
          .then(() => {
            this.success = true
            this.$emit('memberChanged')
          })
          .catch((error) => {
            this.errorMessages = parseResponseErrors(error)
            this.success = false
          })
          .finally(() => this.submitting = false)
      }
    },
    dismiss() {
      if (this.isLeader && !this.isSelf) {
        this.submitting = true
        this.errorMessages = null
        return allianceAPI.patch(`/quest/team/member/${this.member.id}/dismiss/`)
          .then(() => {
            this.success = true
            this.$emit('memberChanged')
          })
          .catch((error) => {
            this.errorMessages = parseResponseErrors(error)
            this.success = false
          })
          .finally(() => this.submitting = false)
      }
    },
  },
}
</script>
