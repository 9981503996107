<template>
  <span class="flag">
    <v-btn
      :disabled="submitting"
      @click="flagDialog = true"
    >
      <v-icon>
        $flag
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="flagDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="$vuetify.breakpoint.thresholds.sm"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>
              $close
            </v-icon>
          </v-btn>
          <v-toolbar-title
            class="text-center mr-12"
            style="width:100%"
          >
            Flag this {{ model }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card
          v-if="success"
          flat
          align="center"
        >
          <v-card-text>
            <v-icon
              size="200"
              color="primary"
            >
              $check
            </v-icon>
            <p class="text-h4">
              Flag Submitted
            </p>
            <p>
              Your report has been submitted! Thank you for your feedback.
            </p>
          </v-card-text>
        </v-card>
        <v-form
          v-else
          ref="form"
          @submit.prevent="submit"
        >
          <v-card-text>
            <div
              v-if="!$online"
              class="description"
            >
              <p>An internet connection is required to report a problem.</p>
            </div>
            <div
              v-else
              class="description"
            >
              <p>Found a problem? Let us know about it.</p>
              <v-alert
                v-for="error in errorMessages"
                :key="error"
                type="error"
              >
                {{ error }}
              </v-alert>
              <v-list v-if="showForm">
                <v-list-item>
                  <v-select
                    v-model="reasonSelect"
                    :disabled="submitting"
                    :items="reasonList"
                    :rules="reasonRules"
                    item-text="description"
                    item-value="id"
                    label="Reason"
                  />
                </v-list-item>
                <v-list-item>
                  <v-textarea
                    v-model="memo"
                    label="Memo"
                    :rules="memoRules"
                    auto-grow
                    :disabled="submitting"
                  />
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              v-if="showForm"
              :disabled="submitting"
              type="submit"
              color="primary"
            >
              Submit
            </v-btn>
            <v-btn
              :disabled="submitting"
              @click="close"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    model: {
      type: String,
      required: true,
    },
    app: {
      type: String,
      required: true,
    },
    objectId: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      submitting: null,
      errorMessages: null,
      flagDialog: false,
      memo: null,
      reasonSelect: null,
      success: null,
      reasonRules: [
        v => !!v || 'Please select a reason for your report',
      ],
      memoRules: [
        v => !!v || 'Please provide some details of the problem you experienced',
        v => (!v || v.length <= 4000) || 'Please restrain yourself to 4,000 characters',
      ],
    }
  },
  computed: {
    ...mapState({
      reasonList: state => state.flagreasons.all,
    }),
    showForm() {
      return this.$online && this.success != true
    },
  },
  watch: {
    flagDialog: function(value) {
      // Fetch the flag reasons when the dialog is opened.
      if (value) {
        this.$store.dispatch('flagreasons/getAll')
      }
    }
  },
  methods: {
    close() {
      this.flagDialog = false
      this.errorMessages = null
      this.success = null
      this.$refs.form.reset()
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.errorMessages = null
      this.success = null
      const data = {
        'object_id': this.objectId,
        'content_type': {"model": this.model, "app_label": this.app},
        'reason': this.reasonSelect,
        'memo': this.memo,
        'version': this.$version,
        'user_agent': window.navigator.userAgent,
      }
      return allianceAPI.post('/alliance/flag/', data)
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.$refs.form.reset()
        })
        .catch((error) => {
          console.log(error)
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
